import { Size } from "../../models/enums";
import { CircularProgress } from "@mui/material";
import { ButtonHTMLAttributes, ReactNode } from "react";
import Icon from "../icons/icon";
import LoadingText from "../loading-text";

type PrimaryButtonProps = {
  id?: string;
  text: string;
  faIcon?: ReactNode;
  size?: Size;
  loading?: boolean;
  loadingText?: string;
  customIcon?: ReactNode;
  dataCy?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryButton = ({
  id,
  className,
  text,
  faIcon,
  size = Size.lg,
  loading,
  loadingText,
  customIcon,
  dataCy,
  ...rest
}: PrimaryButtonProps) => {
  const getHeight = () => {
    switch (size) {
      case Size.lg:
        return "h-big";
      case Size.md:
        return "h-medium";
      case Size.sm:
        return "h-small";
      case Size.free:
        return "h-full";
    }
  };

  const getFontSize = () => {
    switch (size) {
      case Size.lg:
        return "font-bold";
      case Size.md:
        return "text-small";
      case Size.sm:
        return "text-smallest";
    }
  };

  return (
    <div className={`${getHeight()} ${className}`}>
      <button
        id={id}
        data-cy={dataCy ?? id}
        className="flex w-full items-center justify-center space-x-3 min-w-[100px] h-full px-7 rounded-full bg-green disabled:bg-disabled disabled:text-subtitle disabled:cursor-not-allowed whitespace-nowrap"
        {...rest}
      >
        {faIcon && <Icon icon={faIcon} />}
        {customIcon && <Icon icon={customIcon} />}

        {loadingText && loading ? (
          <LoadingText text={loadingText} />
        ) : loading ? (
          <CircularProgress size={20} />
        ) : (
          <p className={`${getFontSize()}`}>{text}</p>
        )}
      </button>
    </div>
  );
};
export default PrimaryButton;
