import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import { PropsWithChildren, ReactNode, useState } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { isEmpty } from "lodash";

type TooltipProps = {
  title: ReactNode;
  disableHoverListener?: boolean;
  arrow?: boolean;
  placement?: "top" | "bottom";
  sideOffset?: number;
  triggerClassName?: string;
} & ClassProps &
  PropsWithChildren;

const Tooltip = ({
  title,
  placement = "top",
  arrow = false,
  sideOffset = 4,
  children,
  className,
  triggerClassName,
}: TooltipProps) => {
  const [open, setOpen] = useState(false);

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root open={open && !isEmpty(title)} onOpenChange={setOpen}>
        <RadixTooltip.Trigger type="button" className={triggerClassName}>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={placement}
            className={cx(
              "TooltipContent rounded-md bg-gray/90 flex items-center justify-center px-3 py-2 text-white text-sm",
              className
            )}
            forceMount
            avoidCollisions
            sticky="always"
            sideOffset={sideOffset}
          >
            {title}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
