import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { getSession } from "next-auth/react";
import * as appConfig from "@/lib/app-config";

/** Prepended to client HTTP requests and removed in the proxy */
export const PROXY_PREFIX = "/api/proxy";

const isClient = typeof window !== "undefined";

const allowApiProxy = () => {
  // For testing all proxy is behind a "flag" -- localStorage.__use_api_proxy
  // This can't be done with a real flag because this is done at the top level
  // and client flags require a hook.
  //
  // Wrappingg here in case some browsers barf and this would prevent anything
  // from working.
  try {
    return localStorage.getItem("__use_api_proxy") === "true"
  } catch (e) {
    console.error("Error accessing localStorage", e);
    return false
  }
}

const api = axios.create({
  // TODO(#1671): Remove call to allowApiProxy after testing in prod
  baseURL: isClient && (appConfig.API_PROXY_ENABLED || allowApiProxy()) ? PROXY_PREFIX : appConfig.API_URL,
  timeout: 300000,
});

if (isClient) {
  api.interceptors.request.use(
    async (config) => {
      const impersonate_artist_id = document.cookie.replace(
        /(?:(?:^|.*;\s*)impersonate-artist-id\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      if (!isEmpty(impersonate_artist_id)) {
        config.headers["impersonate-artist-id"] = impersonate_artist_id;
      }

      return config;
    },
    (error: AxiosError) => {
      console.log("request", error.status, error.code);
      throw error;
    }
  );

  api.interceptors.response.use(
    async (response) => response,
    (error) => {
      // If there's an issue with the network (e.g. API is down), we should
      // navigate to a page that does three things:
      // 1. shows a message that the API server is temporarily down,
      // 2. holds a reference to the page the user was before the error,
      // 3. navigate to the page when the network is back
      if (
        error.code === "ERR_NETWORK" &&
        !window.location.pathname.startsWith("/api-down")
      ) {
        const relativeUrl = window.location.pathname;
        window.location.href = `/api-down?redirectUrl=${relativeUrl}`;
      }
      throw error;
    }
  );
} else {
  getSession().then((session) => {
    if (session?.user?.impersonate_artist_id) {
      api.defaults.headers.common["impersonate-artist-id"] =
        session?.user?.impersonate_artist_id;
    }
  });
}

export default api;
