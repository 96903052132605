import { LoaderParams } from "@/models/models";
import { CircularProgress } from "@mui/material";
import { VariantProps, cva, cx } from "class-variance-authority";
import React, { ButtonHTMLAttributes, ReactNode } from "react";

const button = cva("flex items-center justify-center px-[30px]", {
  variants: {
    variant: {
      primary: "bg-green text-darkGreen",
      dark: "bg-darkGreen text-white",
      inverted: "bg-white text-darkGreen",
      secondary: "bg-orange text-darkGreen",
      blue: "bg-blue text-white",
      "dark-blue": "bg-dark-blue text-white border-dark-blue-700",
      pink: "bg-dark-pink text-white",
      "dark-green": "bg-dark-green text-white border-dark-green-700",
      normal: "bg-cream hover:bg-gray-hover border-white",
      black: "bg-black text-white",
      grey: "bg-[#E4E4E4] text-[#747474]",
      purple: "bg-purple text-white",
      red: "bg-red-600 text-white",
      redpink: "bg-[#E4235D] text-white",
      clear: "border-[#E4E4E4]",
      disabled: "bg-[#E4E4E4] text-[#747474] cursor-not-allowed",
    },
    decoration: {
      default: "rounded-full",
      outlined: "rounded-full border-[2px] border-solid",
      outlinedTest: "rounded-full border-[4px] border-solid border-[#16C350]",
      "non-rounded": "",
    },
    size: {
      default: "h-[50px] min-h-[50px]",
      sm: "h-[36px] min-h-[36px]",
      md: "h-[48px] min-h-[48px]",
      custom: "h-[40px] min-h-[40px]",
    },
  },
  defaultVariants: {
    variant: "primary",
    decoration: "default",
    size: "default",
  },
});

export type FilledButtonProps = VariantProps<typeof button> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
  } & LoaderParams;

const FilledButton = ({
  variant,
  decoration,
  size,
  className,
  leftSlot,
  rightSlot,
  children,
  isLoading,
  disabled,
  ...props
}: FilledButtonProps) => {
  return (
    <button
      className={cx(
        "relative",
        button({
          variant: disabled || isLoading ? "disabled" : variant,
          decoration,
          size,
        }),
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {leftSlot}
      <span className={cx(isLoading ? "invisible" : "visible")}>
        {children}
      </span>
      {isLoading && (
        <span className="absolute inset-0 flex items-center justify-center w-full">
          <CircularProgress className="" size={20} />
        </span>
      )}
      {rightSlot}
    </button>
  );
};

export default FilledButton;
