import React, { useState } from "react";
import { Option } from "../types";
import RecursiveMenuItems from "../recursive-menu-items";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import User from "@/components/icons/lib/user";
import Icon from "@/components/icons/icon";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import SafeImage from "@/components/safe-image";
import Marquee from "@/components/detail-page/marquee";
import { capitalize } from "lodash";

export type DropdownAltOptionProps = {
  option: Option;
  onClick?: () => void;
  onSubItemClick?: (option: Option) => void;
  showMarqueeOnHover?: boolean;
  capitalizeOptionNames?: boolean;
  isSelected?: boolean;
};

const DropdownAltOption = ({
  option,
  onClick,
  onSubItemClick,
  showMarqueeOnHover,
  capitalizeOptionNames,
  isSelected,
}: DropdownAltOptionProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return option.children ? (
    <RecursiveMenuItems
      key={option.id}
      option={option}
      onItemClick={onSubItemClick}
    />
  ) : (
    <DropdownMenu.Item
      key={option.id}
      id={`dropdown-button-option-${option.id}`}
      onClick={onClick}
      className={`navigation-button-${option.id} flex h-big w-full items-center px-4 hover:bg-backgroundLight group/parent first:rounded-t-[14px] last:rounded-b-[14px] cursor-pointer`}
    >
      {!option.image &&
        option.icon &&
        (option.icon === User ? (
          <div className="w-7 h-7 mr-2 min-w-[24px]">
            <div className="flex justify-center items-center h-full w-full bg-gradient-to-br from-purple to-green p-[25%] rounded-full">
              <Icon
                icon={option.icon}
                color="white"
                className="h-full w-full"
              />
            </div>
          </div>
        ) : !option.loading ? (
          <Icon
            icon={option.icon}
            className={`h-[20px] w-[20px] min-w-[20px] mr-3 ${
              option.name === "Audiolab" && "rotate-90"
            }`}
          />
        ) : (
          <ArrowPathIcon className="animate-spin h-[20px] w-[20px] mr-3" />
        ))}
      {option.image && (
        <div className="mr-3 shrink-0">
          <SafeImage
            defaultIcon={
              <div className="flex justify-center items-center w-[24px] h-[24px] bg-gradient-to-br from-purple to-green rounded-full">
                <Icon
                  icon={User}
                  color="white"
                  className="h-full w-full mx-2"
                />
              </div>
            }
            src={option.image}
            alt="image"
            width={28}
            height={28}
            className="rounded-full w-[28px] h-[28px] object-cover"
            blurDataURL="/assets/images/placeholder.png"
          />
        </div>
      )}
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="truncate"
      >
        {isHovered && showMarqueeOnHover ? (
          <Marquee>
            <p className={isSelected ? "font-bold" : "font-light"}>
              {capitalizeOptionNames ? capitalize(option.name) : option.name}
            </p>
          </Marquee>
        ) : (
          <p
            className={isSelected ? "font-bold" : "font-light"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {capitalizeOptionNames ? capitalize(option.name) : option.name}
          </p>
        )}
      </div>
    </DropdownMenu.Item>
  );
};

export default DropdownAltOption;
