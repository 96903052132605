import Mixpanel from "mixpanel-browser";
import type { MixPanelType } from "@/lib/types/mixpanel";
import { useSession } from "next-auth/react";
import * as appConfig from "@/lib/app-config";
import MixpanelEvents from "./events";

const MIXPANEL_TOKEN = appConfig.MIXPANEL_TOKEN;
const MIXPANEL_TESTING = appConfig.MIXPANEL_TESTING;
const MIXPANEL_ENABLED = appConfig.MIXPANEL_ENABLED;

let _mixPanel: typeof Mixpanel | undefined = undefined;

const getMixpanel = (): MixPanelType | undefined => {
  if (!MIXPANEL_TOKEN) return;
  if (!MIXPANEL_ENABLED) return;

  if (_mixPanel) return _mixPanel;

  Mixpanel.init(MIXPANEL_TOKEN, {
    autotrack: true,
    track_pageview: true,
    verbose: MIXPANEL_TESTING,
    test: MIXPANEL_TESTING,
    debug: MIXPANEL_TESTING,
  });
  _mixPanel = Mixpanel;
  // identify once per session
  _mixPanel.identify();
  return _mixPanel;
};

export function trackEvent<Name extends keyof MixpanelEvents>(
  name: Name,
  properties?: MixpanelEvents[Name]
): void {
  const mp = getMixpanel();
  if (!mp) return;
  const props = properties ?? {};
  if (MIXPANEL_TESTING) console.log("[MIXPANEL] trackEvent", name, props);
  mp.track(name, props);
}

export function page() {
  const mp = getMixpanel();
  if (!mp) return;
  if (MIXPANEL_TESTING) console.log("[MIXPANEL] pageview");
  mp.track_pageview();
}

type Session = ReturnType<typeof useSession>;

export function identify(session: Session) {
  const mp = getMixpanel();
  if (!mp) return;
  if (MIXPANEL_TESTING) console.log("[MIXPANEL] identify");

  mp?.identify(session.data?.user?.artistId!);
  const fullName = [
    session.data?.user?.artist?.firstName ?? "NoFirstName",
    session.data?.user?.artist?.lastName ?? "NoLastName",
  ].join(" ");
  mp?.people.set({
    $name: fullName,
    $email: session.data?.user?.email,
    environment: process.env.NODE_ENV,
    nextEnv: appConfig.ENV,
    hasActiveSubscription: session.data?.user?.hasActiveSubscription,
    hasLegacyAgreement: session.data?.user?.hasLegacyAgreement,
    hasConnectPaymentEnabled: session.data?.user?.hasConnectPaymentEnabled,
    hasStripeConnectAccount: session.data?.user?.hasStripeConnectAccount,
    isLabelAccount: session.data?.user?.isLabelAccount,
    subscriptionType: session.data?.user?.subscription?.type,
    subscriptionProvider: session.data?.user?.subscription?.provider,
  });
}
