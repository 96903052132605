import React, { ReactNode, useEffect } from 'react';
import { StatsigProvider as StatsigReactProvider } from '@statsig/react-bindings';
import { useSession } from 'next-auth/react';
import * as appConfig from '@/lib/app-config';

interface StatsigProviderProps {
  children: ReactNode;
}

export const StatsigProvider = ({ children }: StatsigProviderProps) => {
  const clientKey = appConfig.STATSIG_CLIENT_KEY;
  const { data: session, status } = useSession();

  const [isSessionReady, setIsSessionReady] = React.useState(false);

  useEffect(() => {
    if (status !== 'loading') {
      setIsSessionReady(true);
    }
  }, [status]);

  if (!clientKey) {
    console.warn('Statsig client API key not found in environment variables');
    return <>{children}</>;
  }

  if (!isSessionReady) {
    return null; // or a loading spinner, or some other fallback UI
  }



  // Prepare user object for Statsig with proper user identification
  const user = {
    // Use cognitoId as primary identifier, fall back to artistId, then email, then anonymous
    userID: session?.user?.cognitoId || session?.user?.artistId || session?.user?.email || 'anonymous',
    email: session?.user?.email,
    custom: {
      isAuthenticated: status === 'authenticated',
      artistId: session?.user?.artistId ?? '',
      cognitoId: session?.user?.cognitoId,
      stripeCustomerId: session?.user?.stripeCustomerId ?? '',
      hasActiveSubscription: session?.user?.hasActiveSubscription,
      hasLegacyAgreement: session?.user?.hasLegacyAgreement,
      hasConnectPaymentEnabled: session?.user?.hasConnectPaymentEnabled,
      hasStripeConnectAccount: session?.user?.hasStripeConnectAccount,
      isLabelAccount: session?.user?.isLabelAccount,
      subscriptionType: session?.user?.subscription?.type,
      subscriptionStatus: session?.user?.subscription?.status,
    },
  };

  return (
    <StatsigReactProvider
      sdkKey={clientKey}
      user={user}
      options={{
        environment: {
          tier: process.env.NODE_ENV === 'production' ? 'production' : 'development',
        },
      }}
    >
      {children}
    </StatsigReactProvider>
  );
};

export default StatsigProvider;

