/*
 * Config (build time) constants for the app. All access to environment variables
 * should be done through these constants so that you can run the app without any
 * aditional config. This allows us to run testsi n different environments and not
 * have to worry about consistency.
 * 
 * In order to do that, youu must:
 * 
 *   1. Only reference process.env.NEXT_PUBLIC_ variables directly from this file.
 *   2. All constants need to have a well defined default value that is statically 
 *      determined.
 *   3. Do not fall back to a default value when referencing these values. e.g.
 *   4. All constants in the file should be of the form:
 * 
 *      export const CONST_NAME = process.env.NEXT_PUBLIC_CONST_NAME === "default value";
 *      export const CONST_BOOL = process.env.NEXT_PUBLIC_CONST_BOOL === "true";
 */

const neverProd = (value: boolean) => {
  if (value && process.env.NODE_ENV === "production") {
    throw new Error("This value should never be used in production");
  }
  return value;
}

/**
 * Stripe public API key
 */
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY || "pk_test_51M5a1PG2zq888UedySxf0ZyYOknIpdAOK9nYdtKgiEb6OMusKX2J4KVwCAt1osiSN2v2LXSTsIQjkcJC0qkwanSH00bPLCVKZg";

/**
 * PayPal client ID for integration
 */
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || "Acp5ZUEJCUQBZzFLbApXUnmepr6G1SJX3byPFznihFQwUhzSBkRW_I0AlC6XGPn4Oi_gfXZObqz1DU6N";

/**
 * Statsig client key for feature flags and analytics
 * TODO: Maybe set this to empty default so we avoid locally and while testing?
 */
export const STATSIG_CLIENT_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY || "client-ixUFNri2FnEkkajiRmElQhCgvd3wCGEv9XEU1xb0NeN";

/**
 * Base URL for API requests. 
 *   - Client requests are routed thorugh the proxy endpoint before ultimately
 *     ending up here
 *   - Server requests go directly here.
 */
export const API_URL = process.env.NEXT_PUBLIC_API_URL || "https://staging-api.licenselounge.com";

/** Deugging in the `/api/proxy` endpoint. */
export const DEBUG_API_PROXY = neverProd(process.env.NEXT_PUBLIC_DEBUG_API_PROXY === "true");

/** 
 * Whether to enable the API proxy. 
 * 
 * Because the api-config initializationi is at top-level, we can't use 
 a flag to get this, so am using this. 
 */
export const API_PROXY_ENABLED = process.env.NEXT_PUBLIC_API_PROXY_ENABLED === "true";
  
/**
 * Current application environment (development, staging, production)
 */
type AppEnvironment = "staging" | "production";
export const APP_ENVIRONMENT = (process.env.NEXT_PUBLIC_APP_ENVIRONMENT || "staging") as AppEnvironment;
if (!["staging", "production"].includes(APP_ENVIRONMENT)) {
  throw new Error(`Invalid APP_ENVIRONMENT: ${APP_ENVIRONMENT}`);
}

/**
 * Public bucket name for assets
 * 
 */
export const PUBLIC_BUCKET = process.env.NEXT_PUBLIC_PUBLIC_BUCKET || "licenselounge-public";

/**
 * CloudFront host for CDN access
 */
export const CLOUDFRONT_HOST = process.env.NEXT_PUBLIC_CLOUDFRONT_HOST || "d3c9w4wiq1cw6b.cloudfront.net";

/**
 * Mixpanel token for analytics
 */
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "8e030d423b189545240f04fe8b14dc07";

/**
 * Whether to enable Mixpanel testing mode.
 */
export const MIXPANEL_TESTING = process.env.NEXT_PUBLIC_MIXPANEL_TESTING === "true";

/**
 * Flag to enable/disable Mixpanel testing mode
 */
export const MIXPANEL_ENABLED = process.env.NEXT_PUBLIC_MIXPANEL_ENABLED === "true"; // Default to false when not specified

/**
 * Current environment. We don't set this, so leave it possibly-undefined.
 */
export const ENV = process.env.NEXT_PUBLIC_ENV;

/**
 * Google Analytics ID
 */
export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || "G-XJVN657T2T";
