import { useRouter } from "next/router";
import { useEffect } from "react";
import { page } from "@/lib/utils/mixpanel";

export const usePageTracking = () => {
    const router = useRouter();

    useEffect(() => {
        router.events.on('routeChangeComplete', page)
        return () => {
            router.events.off('routeChangeComplete', page)
        }
    }, [router.events])
};