import { Dialog, RadioGroup, Switch, Transition } from "@headlessui/react";
import { Slider } from "@mui/material";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import {
  licensingTypes,
  recency,
  rights,
  searchCategories,
} from "../data/dummy";
import { Categories, FilterOrder, FilterType, Size } from "../models/enums";
import {
  Filter,
  FilterContextEntityType,
  Mood,
  SearchCategory,
} from "../models/models";
import { useSearchFilters } from "../src/contexts/FilterContext";
import PrimaryButton from "./buttons/primary-button";
import SecondaryButton from "./buttons/secondary-button";
import Icon from "./icons/icon";
import FilterPill from "./pills/filter-pill";
import { getBeatsCountAsync } from "../lib/beats-manager";
import { getSoundKitsCountAsync } from "../lib/soundkits-manager";
import { getSongsCountAsync } from "../lib/songs-manager";
import { parseQueryString } from "@/lib/utils/parseQueryString";
import { useNavbarCategoryContext } from "@/lib/atoms/linked-filters";
import Tooltip from "./tooltip";
import Check from "./icons/lib/check";
import Close from "./icons/lib/close";
import FilterIcon from "./icons/lib/filter-icon";
import { getIconFromMood } from "@/lib/utils/getIconFromMood";
import toast from "react-hot-toast";

interface FilterProps {
  className?: string;
}

const sortFilters = (a: Filter, b: Filter) => {
  const orderA = FilterOrder[a.type as any] as unknown as number;
  const orderB = FilterOrder[b.type as any] as unknown as number;
  return orderA - orderB;
};

const FilterView = ({ className }: FilterProps) => {
  // hooks
  const router = useRouter();
  const {
    appliedFilters,
    clearAllFilters,
    selectedCategory,
    setPopupVisible,
    isFilterPopupVisible,
    resultsLoading,
    handleSearch,
  } = useSearchFilters();
  const [, setNavbarCategory] = useNavbarCategoryContext();

  const [resultsCount, setResultsCount] = useState<number>(0);

  useEffect(() => {
    const { search } = parseQueryString();

    try {
      switch (selectedCategory.name) {
        case "Beats":
          getBeatsCountAsync(search, appliedFilters).then((count) => {
            setResultsCount(count.count);
          });
          break;
        case "Songs":
          getSongsCountAsync(search, appliedFilters).then((count) => {
            setResultsCount(count.count);
          });
          break;
        case "Sounds":
          getSoundKitsCountAsync(search, appliedFilters).then((count) => {
            setResultsCount(count.count);
          });
          break;
      }
    } catch (err) {
      console.error(err);
      toast.error("There was an error while requesting count.");
    }
  }, [appliedFilters, selectedCategory]);

  // actions
  const showResults = async () => {
    setPopupVisible(false);
    setNavbarCategory(selectedCategory);
    const { search } = parseQueryString();

    if (search) {
      // Add the search term to the url
      router.push(`/${selectedCategory?.name.toLowerCase()}?search=${search}`);
    } else if (
      window.location.pathname === `/${selectedCategory?.name.toLowerCase()}`
    ) {
      // Perform a normal search, we're on the same page
      handleSearch({
        quick: false,
        category: selectedCategory?.name as FilterContextEntityType,
        limit: 30,
        offset: 0,
        searchQuery: "",
        filters: appliedFilters,
      });
    } else {
      // Navigate to the search page, since we are not on the selected one.
      router.push(`/${selectedCategory?.name.toLowerCase()}`);
    }
  };

  return (
    <div className="relative">
      <div
        className={`flex flex-col gap-2 lg:flex-row items-start lg:items-center overflow-x-auto lg:overflow-hidden lg:mb-0 mb-3 ${
          appliedFilters.length == 0 && "md:mb-[50px]"
        } ${className}`}
      >
        <SecondaryButton
          id="filter-button-show"
          text="Filters"
          icon={FilterIcon}
          onClick={() => setPopupVisible(!isFilterPopupVisible)}
          badgeNumber={appliedFilters.length + 1}
          size={Size.xl}
        />

        <div className="items-center overflow-auto hidden lg:flex">
          <div className="lg:flex hidden items-center lg:ml-10 gap-4">
            <div
              className="flex items-center h-medium space-x-3 rounded-full py-1 px-3 border-[1px] border-grayLight truncate"
              key={`applied-category`}
            >
              <p>{selectedCategory.name}</p>
              <Icon icon={selectedCategory.icon} color="gray" />
            </div>

            {appliedFilters.sort(sortFilters).map(FilterPill).slice(0, 3)}
          </div>
          {appliedFilters.length > 0 && (
            <div className="flex lg:hidden items-center mt-[60px] lg:ml-10 gap-4">
              {appliedFilters.sort(sortFilters).map(FilterPill)}
            </div>
          )}
          {appliedFilters.length > 3 && (
            <div className="hidden lg:flex items-center px-3 h-medium rounded-full border-[1px] border-grayLight ml-4">
              <p className="font-bold">{`+${appliedFilters.length - 3}`} </p>
            </div>
          )}
        </div>

        <Transition appear show={isFilterPopupVisible} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[2000]"
            onClose={() => setPopupVisible(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75" />
            </Transition.Child>

            <div className="fixed inset-0">
              <div className="flex min-h-full justify-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="flex flex-col absolute bottom-0 top-0  lg:bottom-[5%] lg:top-[5%]  lg:h-[90%] w-full lg:w-[1000px] lg:rounded-3xl bg-background lg:bg-white overflow-y-auto ">
                    <FiltersPopup onClose={() => setPopupVisible(false)} />
                    {/* Filter results buttons */}
                    <div className="sticky bottom-0 z-20 mx-auto pl-10 pr-5 py-[30px] w-full lg:w-[970px] flex bg-background lg:bg-white items-center justify-between lg:justify-end space-x-10">
                      <button
                        id="filter-button-clear"
                        onClick={() => clearAllFilters()}
                      >
                        Clear all filters
                      </button>
                      <PrimaryButton
                        id="filter-button-apply"
                        disabled={resultsLoading}
                        onClick={showResults}
                        text={`Show ${
                          resultsCount ? resultsCount : ""
                        } results`}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

export default FilterView;

interface FiltersPopupProps {
  onClose: Function;
}

export const MAX_PRICE = 1000;
export const MAX_BPM = 200;
export const FiltersPopup = ({ onClose }: FiltersPopupProps) => {
  // hooks
  const router = useRouter();

  const {
    bpmRange,
    selectedLicense,
    selectedRight,
    selectedRecency,
    selectedMoods,
    selectedStyles,
    selectedKeys,
    majorEnabled,
    minorEnabled,
    keysOptions,
    selectedKinds,
    addFilter,
    removeFilter,
    setSelectedLicense,
    setSelectedRecency,
    setMajorEnabled,
    setMinorEnabled,
    isSharp,
    setIsSharp,
    setBpmRange,
    moods,
    kinds,
    subGenres,
    genres,
    handleSingleSelect,
    handleMultiSelect,
    selectedCategory,
    setSelectedCategory,
    selectedGenre,
    setSelectedGenre,
  } = useSearchFilters();

  // state
  const [localBpmRange, setLocalBpmRange] = useState(bpmRange);

  // effects
  useEffect(() => {
    if (minorEnabled) {
      setMajorEnabled(false);
    } else {
      setMajorEnabled(true);
    }
  }, [minorEnabled, setMajorEnabled]);

  useEffect(() => {
    if (majorEnabled) {
      setMinorEnabled(false);
    } else {
      setMinorEnabled(true);
    }
  }, [majorEnabled, setMinorEnabled]);

  const handleCategoryChange = (category: SearchCategory) => {
    if (category.name === "Creators") {
      const { search } = parseQueryString();
      if (search) {
        router.push(`/creators?search=${search}`);
      } else {
        router.push("/creators");
      }
      onClose();
    }
    setSelectedCategory(category);
  };

  // actions
  const renderOptionItem = (option: SearchCategory, index: number) => {
    return (
      <RadioGroup.Option
        id={`filter-radio-category-${index}`}
        key={`category-${index}`}
        value={option}
      >
        {({ checked }) => (
          <button
            key={`category-${option.name}`}
            className={`flex items-center justify-start h-big w-full rounded-lg mb-3 space-x-3 px-5  ${
              checked
                ? "border-[2px] border-purple"
                : "border-[1px] border-grayLight"
            }`}
          >
            <Icon icon={option.icon} color={checked ? "#5504d4" : "black"} />
            <p className={`${checked ? "text-purple" : "text-black"}`}>
              {option.name}
            </p>
          </button>
        )}
      </RadioGroup.Option>
    );
  };

  const renderPillItem = (item: Filter, index: number) => {
    const isDisabled =
      selectedCategory?.name === Categories.songs &&
      item.name !== "Sync" &&
      item.type === FilterType.licenseType;
    return (
      <RadioGroup.Option
        id={`filter-radioPill-${item.type}-${index}`}
        key={`pill-${index}`}
        value={item.id}
        disabled={isDisabled}
      >
        {({ checked }) => (
          <div
            className={`flex space-x-3 rounded-full py-1 px-3 cursor-pointer ${
              checked
                ? "border-purple border-[2px] "
                : !isDisabled
                ? "border-grayLight border-[1px]"
                : "bg-grayLighter"
            }`}
          >
            <button
              className={`${
                checked
                  ? "text-purple"
                  : !isDisabled
                  ? "text-gray"
                  : "text-grayLight"
              }`}
            >
              {item.name}
            </button>
          </div>
        )}
      </RadioGroup.Option>
    );
  };

  const renderMultiSelectItem = (item: Filter | Mood, index: number) => {
    const isSelected: boolean =
      selectedStyles.some(
        (sub) => sub?.id === item.id && item.type === sub?.type
      ) ||
      selectedMoods.some(
        (mood) => mood?.id === item.id && item.type === mood?.type
      ) ||
      selectedKinds.some(
        (kind) => kind?.id === item.id && item.type === kind?.type
      ) ||
      selectedRight.some(
        (right) => right?.id === item.id && item.type === right?.type
      );

    const mood = item as Mood;

    return (
      <Tooltip
        title={mood.name.concat(
          ", ",
          mood.relatedMoods?.map((rel) => rel.name).join(", ")
        )}
        arrow
        disableHoverListener={item.type !== FilterType.moodsType}
        key={`pill-${index}`}
      >
        <div>
          {item.type !== FilterType.moodsType ? (
            <div
              className={`flex space-x-3 rounded-full py-1 px-3 cursor-pointer ${
                isSelected
                  ? "border-purple border-[2px] "
                  : "border-grayLight border-[1px]"
              }`}
            >
              <button
                id={`filter-button-${item.type}-${index}`}
                onClick={() => handleMultiSelect(item)}
                className={`${isSelected ? "text-purple" : "text-gray"}`}
              >
                {item.name}
              </button>
            </div>
          ) : item.name !== "Any" ? (
            <Icon
              viewBox="0 0 32 32"
              icon={getIconFromMood(item.name)}
              className={`w-[55px] h-[55px] object-cover cursor-pointer ${
                isSelected ? "fill-purple" : "fill-gray"
              }`}
              onClick={() => handleMultiSelect(item)}
            />
          ) : (
            <div
              className={`flex space-x-3 rounded-full py-1 px-3 cursor-pointer ${
                isSelected
                  ? "border-purple border-[2px] "
                  : "border-grayLight border-[1px]"
              }`}
            >
              <button
                id={`filter-button-${item.type}-${index}`}
                onClick={() => handleMultiSelect(item)}
                className={`${isSelected ? "text-purple" : "text-gray"}`}
              >
                {item.name}
              </button>
            </div>
          )}
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      className={
        selectedCategory.name === Categories.creators ? "h-[800px]" : ""
      }
    >
      {/* Title Desktop*/}
      <div className="hidden lg:flex lg:flex-col py-[30px] relative lg:sticky top-0 bg-white z-10">
        <h3 className="text-center">Filters</h3>
        <button
          id="filter-button-close"
          className="absolute right-0 top-0 mr-7 mt-7"
          onClick={() => onClose()}
        >
          <Icon icon={Close} className="fill-gray h-medium w-medium" />
        </button>
      </div>

      {/* Title Mobile */}
      <div className="lg:hidden flex py-5 px-5 border-b-[1px] border-grayLight space-x-5 sticky top-0 bg-background z-10">
        <button id="mobile-filter-button-close" onClick={() => onClose()}>
          <Icon icon={Close} className="fill-gray h-[25px] w-[25px]" />
        </button>
        <h3 className="text-center text-medium">Filters</h3>
      </div>

      <div className="px-5 lg:px-[50px] pb-[130px]">
        {/* Category */}
        <div className="flex flex-col space-y-5 py-[30px] border-b-[1px] border-grayLight">
          <h4 className="w-full mb-1 text-xl lg:text-2xl">Category</h4>
          <RadioGroup
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="flex flex-wrap gap-4"
          >
            {searchCategories.map(renderOptionItem)}
          </RadioGroup>
        </div>

        {/* Licensing types */}
        {selectedCategory.name !== Categories.creators && (
          <div className="py-[30px] border-b-[1px] border-grayLight ">
            <h4 className="mb-5 text-xl lg:text-2xl">Licensing Types</h4>
            <RadioGroup
              value={selectedLicense?.id}
              onChange={(id: string) => {
                const filter = licensingTypes.find(
                  (licensingType) => licensingType?.id === id
                );

                if (filter) {
                  setSelectedLicense(filter);
                }
              }}
              className="flex flex-wrap gap-4"
            >
              {(selectedCategory.name === Categories.beats ||
                selectedCategory.name === Categories.songs) &&
                licensingTypes
                  .filter((license) => license.id !== "4")
                  .map(renderPillItem)}
              {selectedCategory.name === Categories.sounds &&
                licensingTypes
                  .filter((license) => license.id !== "2" && license.id !== "3")
                  .map(renderPillItem)}
            </RadioGroup>
            {selectedLicense !== licensingTypes[0] &&
              selectedLicense !== licensingTypes[3] && (
                <p className="text-small mt-3">
                  {selectedLicense.name === "Recording"
                    ? "*Make a song"
                    : "*Add music to your video projects"}
                </p>
              )}
          </div>
        )}

        {/* Kind (sounds only) */}
        {selectedCategory.name === Categories.sounds && (
          <div className="py-[30px] border-b-[1px] border-grayLight ">
            <h4 className="mb-5 text-xl lg:text-2xl">Kind</h4>
            <div className="flex flex-wrap justify-start gap-4">
              {kinds?.map(renderMultiSelectItem)}
            </div>
          </div>
        )}

        {/* Rights */}
        {selectedCategory.name !== Categories.sounds &&
          selectedCategory.name !== Categories.creators && (
            <div className="py-[30px] border-b-[1px] border-grayLight">
              <h4 className="mb-5 text-xl lg:text-2xl">Rights</h4>
              <div className="flex flex-wrap justify-start gap-4">
                {selectedLicense.name === "Sync"
                  ? rights
                      .filter((right) => right.id === "3")
                      .map(renderMultiSelectItem)
                  : rights?.map(renderMultiSelectItem)}
              </div>
            </div>
          )}

        {/* BPM slider */}
        {selectedCategory.name !== Categories.sounds &&
          selectedCategory.name !== Categories.creators && (
            <div className="py-[30px] border-b-[1px] border-grayLight">
              <h4 className="mb-5 text-xl lg:text-2xl">BPM</h4>
              <div className="flex justify-between">
                <p className="text-gray">0</p>
                <p className="text-gray">{`200+`} </p>
              </div>
              <Slider
                id={`filter-slider-bpm`}
                max={MAX_BPM}
                min={0}
                value={localBpmRange}
                onChange={(event, value) => {
                  const valuesArray = value as number[];
                  valuesArray[1] === 0 ? (valuesArray[1] = 1) : "";
                  setLocalBpmRange(valuesArray);
                }}
                onChangeCommitted={(event, value) => {
                  const valuesArray = value as number[];
                  valuesArray[1] === 0 ? (valuesArray[1] = 1) : "";
                  addFilter({
                    name: `${valuesArray[0]}-${valuesArray[1]}`,
                    type: FilterType.bpmType,
                  });
                }}
                valueLabelDisplay="auto"
                sx={{
                  color: "#5504d4",
                  "& .MuiSlider-rail": {
                    color: "gray",
                  },
                  "& .MuiSlider-valueLabel": {
                    backgroundColor: "white",
                    boxShadow: "0px 7px 10px 3px #eeeeee",
                    color: "black",
                  },
                }}
              />
            </div>
          )}

        {/* Key signature */}
        {selectedCategory.name !== Categories.sounds &&
          selectedCategory.name !== Categories.creators && (
            <div className="py-[30px] border-b-[1px] border-grayLight">
              <h4 className="mb-5 text-xl lg:text-2xl">Key Signature</h4>
              <div className="flex flex-wrap pb-5 gap-5">
                {/* Major/Minor Group */}
                <div className="flex w-[250px] justify-start">
                  {/* Major toggle switch */}
                  <div className="flex items-center">
                    <Switch
                      id="filter-switch-major"
                      checked={majorEnabled}
                      onChange={setMajorEnabled}
                      className={`${majorEnabled ? "bg-purple" : "bg-grayLight"}
                  relative inline-flex h-[21px] w-[40.5px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={`${
                          majorEnabled ? "translate-x-6" : "translate-x-0"
                        }
                    translate-y-px pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                    <p className="ml-2 mr-10">Major</p>
                  </div>

                  {/* Minor toggle switch */}
                  <div className="flex items-center">
                    <Switch
                      id="filter-switch-minor"
                      checked={minorEnabled}
                      onChange={setMinorEnabled}
                      className={`${minorEnabled ? "bg-purple" : "bg-grayLight"}
                  relative inline-flex h-[21px] w-[40.5px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={`${
                          minorEnabled ? "translate-x-6" : "translate-x-0"
                        }
                    translate-y-px pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                    <p className="ml-2 mr-10">Minor</p>
                  </div>
                </div>

                {/* Sharp/Flat group */}
                <div className="flex w-[200px] justify-start">
                  {/* Sharp checkbox */}
                  <div className="flex items-center mr-10 ml-1 gap-1">
                    <button
                      id="filter-checkbox-sharp"
                      onClick={() => setIsSharp(!isSharp)}
                      className={`flex items-center justify-center rounded h-[20px] w-[20px] mr-2 ${
                        isSharp ? "bg-purple" : "border-[2px] border-grayLight"
                      }`}
                    >
                      {isSharp && <Icon icon={Check} className="fill-white" />}
                    </button>
                    Sharp
                  </div>

                  {/* Flat checkbox */}
                  <div className="flex items-center ml-1 gap-1">
                    <button
                      id="filter-checkbox-flat"
                      onClick={() => setIsSharp(!isSharp)}
                      className={`flex items-center justify-center rounded h-[20px] w-[20px] mr-2 ${
                        !isSharp ? "bg-purple" : "border-[2px] border-grayLight"
                      }`}
                    >
                      {!isSharp && <Icon icon={Check} className="fill-white" />}
                    </button>
                    Flat
                  </div>
                </div>
              </div>

              {/* Key signature Desktop  */}
              <div className="max-w-md flex flex-col">
                <div className="flex justify-center w-full h-[60px] gap-2 overflow-hidden">
                  {keysOptions
                    ?.filter((k) => {
                      if (majorEnabled) {
                        if (isSharp) {
                          return k.name.includes("♯") && k.isMajor;
                        }
                        return k.name.includes("♭") && k.isMajor;
                      } else {
                        if (isSharp) {
                          return k.name.includes("♯") && !k.isMajor;
                        }
                        return k.name.includes("♭") && !k.isMajor;
                      }
                    })
                    .map((k, index) => {
                      const isSelected: boolean = selectedKeys?.some(
                        (key) => key?.id === k.id && k.type === key?.type
                      )!;

                      return (
                        <button
                          id={`filter-button-keysa-${index}`}
                          key={`keyTop-${index}`}
                          className={`flex text-gray border-[1px] h-12 border-grayLight w-12 rounded-full ${
                            index === 1 && "mr-[55px]"
                          }`}
                          onClick={() => handleSingleSelect(k)}
                        >
                          <div
                            className={`flex justify-center items-center w-full h-full cursor-pointer ${
                              isSelected
                                ? "border-purple border-[2px] rounded-full"
                                : "border-gray"
                            }`}
                          >
                            <p
                              className={`${
                                isSelected ? "text-purple" : "text-gray"
                              }`}
                            >
                              {k.name}
                            </p>
                          </div>
                        </button>
                      );
                    })}
                </div>
                <div className="flex justify-center gap-2 w-full h-[60px] mt-[-1px] overflow-hidden">
                  {keysOptions
                    ?.filter((k) => {
                      if (majorEnabled) {
                        return (
                          k.isMajor &&
                          !k.name.includes("♯") &&
                          !k.name.includes("♭")
                        );
                      }
                      return (
                        !k.isMajor &&
                        !k.name.includes("♯") &&
                        !k.name.includes("♭")
                      );
                    })
                    .map((k, index: number) => {
                      const isSelected: boolean = selectedKeys?.some(
                        (key) => key?.id === k.id && k.type === key?.type
                      )!;
                      return (
                        <button
                          id={`filter-button-keysb-${index}`}
                          key={`keyBottom-${index}`}
                          className={`flex text-gray border-[1px] rounded-lg border-grayLight w-12 h-12`}
                          onClick={() => handleSingleSelect(k)}
                        >
                          <div
                            className={`flex justify-center items-center w-full h-full cursor-pointer ${
                              isSelected
                                ? "border-purple border-[2px] rounded-lg"
                                : "border-gray"
                            }`}
                          >
                            <p> {k.name} </p>
                          </div>
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

        {/* Genre */}
        {selectedCategory.name !== Categories.creators && (
          <div className="py-[30px] border-b-[1px] border-grayLight">
            <h4 className="mb-5 text-xl lg:text-2xl">Genres</h4>
            <RadioGroup
              value={selectedGenre?.id}
              onChange={(genreId: string) => {
                const genre = genres?.find((genre) => genre.id === genreId)!;

                if (genre.name !== "Any") {
                  setSelectedGenre(genre);
                  addFilter(genre);
                } else {
                  setSelectedGenre(genres?.at(0)!);
                  removeFilter(genre);
                }
              }}
              className="flex flex-wrap gap-4"
            >
              {genres?.map(renderPillItem)}
            </RadioGroup>
          </div>
        )}

        {/* Styles */}
        {selectedCategory.name !== Categories.creators &&
          selectedGenre?.id !== "1" && (
            <div className="py-[30px] border-b-[1px] border-grayLight">
              <h4 className="mb-5 text-xl lg:text-2xl">Styles</h4>
              <div className="flex flex-wrap justify-start gap-4">
                {subGenres
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(renderMultiSelectItem)}
              </div>
            </div>
          )}

        {/* Moods */}
        {selectedCategory.name !== Categories.sounds &&
          selectedCategory.name !== Categories.creators && (
            <div className="py-[30px] ">
              <h4 className="mb-5 text-xl lg:text-2xl">Moods</h4>
              <div className="flex flex-wrap gap-4 items-center">
                {moods?.map(renderMultiSelectItem)}
              </div>
            </div>
          )}

        {/* Date Added */}
        {selectedCategory.name !== Categories.creators && (
          <div className="py-[30px] border-b-[1px] border-grayLight">
            <h4 className="mb-5 text-xl lg:text-2xl">Date Added</h4>
            <RadioGroup
              value={selectedRecency?.id}
              onChange={(id: string) => {
                const filter = recency?.find((r) => r.id === id);

                if (filter) {
                  setSelectedRecency(filter);
                  addFilter(filter);
                }
              }}
              className="flex flex-wrap gap-4"
            >
              {recency.map(renderPillItem)}
            </RadioGroup>
          </div>
        )}
      </div>
    </div>
  );
};
