import Image from "next/image";
import { getTimeStamp, isBeat } from "@/lib/helpers";
import { Size } from "@/models/enums";
import { Beat, LoaderParams } from "@/models/models";
import Icon from "@/components/icons/icon";
import { useAudioPlayer } from "@/src/contexts/AudioPlayerContext";
import { useEffect, useState } from "react";
import Headphones from "@/components/icons/lib/headphones";
import Play from "@/components/icons/lib/play";
import Pause from "@/components/icons/lib/pause";
import Song from "@/components/icons/lib/song";
import Edit from "@/components/icons/lib/edit";
import { cx } from "class-variance-authority";
import BaseSkeleton from "../base-skeleton";

export type TrackArtProps = {
  id?: string;
  item: Beat;
  size?: Size;
  play?: boolean;
  edit?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onPlay?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: JSX.Element;
  className?: string;
} & LoaderParams;

const TrackArt = ({
  id,
  item,
  size,
  play = false,
  edit = false,
  disabled = false,
  onClick,
  onPlay,
  children,
  className,
  isLoading,
}: TrackArtProps) => {
  // hooks
  const { selectedAudio, playing } = useAudioPlayer();

  // states
  const [src, setSrc] = useState<string | null>(item?.cover);

  useEffect(() => {
    setSrc(item?.cover ?? "" );
  }, [item?.cover]);

  // actions
  const getSize = () =>
    size == "xs"
      ? 50
      : size == "sm"
      ? 200
      : size == "md"
      ? 100
      : size == "lg"
      ? 550
      : size == "xl"
      ? 266
      : size == "free"
      ? 1200
      : 550;
  const getImageClasses = () =>
    size == "xs"
      ? "w-[45px] h-[45px] lg:w-[55px] lg:h-[55px] rounded-lg"
      : size == "sm"
      ? "w-[70px] h-[70px] lg:w-[81px] lg:h-[81px] rounded-lg"
      : size == "md"
      ? "w-[100px] h-[100px] rounded-lg"
      : size == "lg"
      ? "w-full rounded-3xl"
      : size == "xl"
      ? "min-w-[262px] min-h-[262px] max-h-[262px] max-w-[262px] lg:min-w-[266px] lg:min-h-[266px] lg:max-w-[266px] rounded-3xl"
      : size == "free"
      ? "w-full h-full rounded-3xl"
      : "";
  const getIconSize = () => (size == "xs" ? 30 : size == "sm" ? 30 : 80);

  const doPlay = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onPlay) {
      onPlay?.(e);
      e.stopPropagation();
    }
  };

  if (isLoading) {
    return (
      <BaseSkeleton
        className={cx(getImageClasses(), "aspect-square", className)}
      />
    );
  }

  return (
    <div
      id={id}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        onClick?.(e)
      }
      className={`grid-trackArt-play relative flex overflow-hidden cursor-pointer bg-white shadow-[0_0_15px_5px] shadow-[#000000]/[0.1] aspect-square flex-shrink-0 ${getImageClasses()} ${
        className ?? ""
      }`}
    >
      {src ? (
        <Image
          src={src}
          height={getSize()}
          width={getSize()}
          objectFit="cover"
          className="flex object-cover w-full"
          placeholder="blur"
          blurDataURL={"/assets/images/placeholder.png"}
          alt={`Cover of ${item?.name}`}
          onError={() => setSrc(null)}
        />
      ) : (
        <div className="flex justify-center items-center h-full w-full bg-gradient-to-br from-purple to-green p-[25%]">
          <Icon
            icon={item && isBeat(item) ? Headphones : Song}
            color="white"
            className="w-full h-full"
          />
        </div>
      )}
      {play && !disabled && (
        <div
          className={`absolute flex justify-center items-center top-0 w-full bg-shadow h-full z-10 opacity-${
            selectedAudio?.id === item?.id ? "100" : "0"
          } hover:opacity-100 transition-all`}
        >
          <div
            onClick={(e) => (item?.status !== "Processing" ? doPlay(e) : null)}
            className={`h-[${getIconSize()}px] w-[${getIconSize()}px] group`}
          >
            <Icon
              icon={selectedAudio?.id === item?.id && playing ? Pause : Play}
              className={`h-full w-full fill-green ${
                selectedAudio?.id === item?.id
                  ? "block"
                  : "hidden group-hover:block"
              }`}
            />
          </div>
        </div>
      )}
      {edit && (
        <div className="absolute inset-0 flex transition-opacity duration-300 bg-black opacity-0 bg-opacity-60 hover:opacity-100">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="flex items-center gap-2">
              <Icon
                icon={Edit}
                color="white"
                className="h-[20px] w-[20px] mb-1"
              />
              <p className="text-white">Edit</p>
            </div>
          </div>
        </div>
      )}
      {disabled && (
        <div className="absolute inset-0 flex bg-white bg-opacity-30" />
      )}
      {children}
    </div>
  );
};

export default TrackArt;
