import { getTimeStamp } from "@/lib/helpers";
import { isNull } from "lodash";
import Image, { ImageProps } from "next/image";
import React, { ReactNode, useEffect, useState } from "react";

export type SafeImageProps = Omit<ImageProps, "src"> & {
  alt: string;
  noCache?: boolean;
  src?: string | null;
} & (
    | { defaultImage: string; defaultIcon?: ReactNode }
    | { defaultIcon: ReactNode; defaultImage?: string }
  );

const SafeImage = ({
  defaultImage,
  defaultIcon,
  onError,
  alt,
  src,
  ...rest
}: SafeImageProps) => {
  const [error, setError] = useState(false);
  const [internalSrc, setInternalSrc] = useState<string | null | undefined>(
    src
  );

  const handleError = () => {
    setInternalSrc("");
    setError(true);
  };

  useEffect(() => {
    if (src) {
      setInternalSrc(src);
      setError(false);
    }
  }, [src]);

  return (
    <>
      {(error && defaultIcon) || isNull(src) ? (
        defaultIcon
      ) : (
        <Image
          src={internalSrc ?? ""}
          onError={handleError}
          alt={alt}
          {...rest}
        />
      )}
    </>
  );
};

export default SafeImage;
