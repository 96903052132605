import { toast } from "react-hot-toast";
import { BaseEntityType, FilterType } from "../models/enums";
import { Filter, Genre } from "../models/models";
import api from "./api-config";
import { isNil, uniqBy } from "lodash";
import { useQuery } from "react-query";

export type UseGenresParams = {
  getUsed?: boolean;
  includeAny?: boolean;
};

const useBeatsGenres = ({
  getUsed = true,
  includeAny = true,
}: UseGenresParams) => {
  const {
    data: genres,
    isLoading: loading,
    error,
  } = useQuery(
    ["genres", { used: getUsed ? BaseEntityType.beat : undefined, includeAny }],
    async () => {
      const response = await getGenresAsync(
        getUsed ? BaseEntityType.beat : undefined,
        includeAny
      );
      return response.genres;
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError() {
        toast.error("Failed to get beats.");
      },
    }
  );
  return {
    genres,
    loading,
    error,
  };
};

const useSongsGenres = ({
  getUsed = true,
  includeAny = true,
}: UseGenresParams) => {
  const {
    data: genres,
    isLoading: loading,
    error,
  } = useQuery(
    ["genres", { used: getUsed ? BaseEntityType.song : undefined, includeAny }],
    async () => {
      const response = await getGenresAsync(
        getUsed ? BaseEntityType.song : undefined,
        includeAny
      );
      return response.genres;
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError() {
        toast.error("Failed to get genres.");
      },
    }
  );
  return {
    genres,
    loading,
    error,
  };
};

export const useGenres = (
  category?: BaseEntityType | null,
  selectedGenreId?: string | null,
  includeAny = false,
  includeAll = false
) => {
  const { genres: beatsGenres, loading: beatsGenresLoading } = useBeatsGenres({
    getUsed: !includeAll,
    includeAny,
  });
  const { genres: songsGenres, loading: songsGenresLoading } = useSongsGenres({
    getUsed: !includeAll,
    includeAny,
  });

  const allBeatsGenres = includeAny
    ? [
        { id: "1", name: "Any", type: FilterType.genreType } as Genre,
        ...(beatsGenres?.map((beatGenre) => ({
          ...beatGenre,
          type: FilterType.genreType,
        })) ?? []),
      ]
    : beatsGenres;
  const beatGenre = !isNil(allBeatsGenres)
    ? allBeatsGenres?.find((beatGenre, index) =>
        !isNil(selectedGenreId) || (selectedGenreId ?? "") !== ""
          ? beatGenre.id === selectedGenreId
          : includeAny
          ? beatGenre.id === "1"
          : index === 0
      )
    : undefined;
  const allBeatsSubgenres = includeAny
    ? [
        { id: "1", name: "Any", type: FilterType.styleType },
        ...(beatGenre?.subGenres?.map((subGenre) => ({
          ...subGenre,
          type: FilterType.styleType,
        })) ?? []),
      ]
    : null;

  const allSongsGenres = includeAny
    ? [
        { id: "1", name: "Any", type: FilterType.genreType } as Genre,
        ...(songsGenres?.map((songGenre) => ({
          ...songGenre,
          type: FilterType.genreType,
        })) ?? []),
      ]
    : songsGenres;
  const songGenre = allSongsGenres?.find((songGenre, index) =>
    !isNil(selectedGenreId) || (selectedGenreId ?? "") !== ""
      ? songGenre.id === selectedGenreId
      : includeAny
      ? songGenre.id === "1"
      : index === 0
  );
  const allSongsSubgenres = includeAny
    ? [
        { id: "1", name: "Any", type: FilterType.styleType },
        ...(songGenre?.subGenres?.map((subGenre) => ({
          ...subGenre,
          type: FilterType.styleType,
        })) ?? []),
      ]
    : null;

  switch (category) {
    case BaseEntityType.beat:
      return {
        genres: allBeatsGenres,
        subGenres: allBeatsSubgenres,
        loading: beatsGenresLoading,
      };
    case BaseEntityType.song:
      return {
        genres: allSongsGenres,
        subGenres: allSongsSubgenres,
        loading: songsGenresLoading,
      };
    default:
      // Case for all genres
      return {
        genres: uniqBy(
          [...(allBeatsGenres ?? []), ...(allSongsGenres ?? [])],
          (genre) => genre?.id
        ),
        subGenres: uniqBy(
          [...(allBeatsSubgenres ?? []), ...(allSongsSubgenres ?? [])],
          (genre) => genre?.id
        ),
        loading: songsGenresLoading || beatsGenresLoading,
      };
  }
};

export const getGenresAsync = async (
  used?: BaseEntityType,
  includeAny = true
) => {
  const res = await api.get(`/genres`, {
    params: { used },
  });

  const genres = res.data.map((genre: any) => {
    return {
      ...genre,
      subGenres: [
        ...(includeAny
          ? [{ id: "1", name: "Any", type: FilterType.styleType }]
          : []),
        ...genre.subGenres.map((subGenre: any) => {
          return { ...subGenre, type: FilterType.styleType } as Filter;
        }),
      ],
      type: FilterType.genreType,
    } as Genre;
  }) as Genre[];

  return {
    genres: [
      ...(includeAny
        ? [
            {
              id: "1",
              name: "Any",
              type: FilterType.genreType,
              subGenres: [{ id: "1", name: "Any", type: FilterType.styleType }],
            } as Genre,
          ]
        : []),
      ...genres,
    ],
  };
};

export const getGenreById = async (id: string) => {
  const res = await api.get(`/genres/${id}`);

  return {
    genre: res.data as Genre,
  };
};

export const getSubGenresAsync = async () => {
  const res = await api.get("/sub-genres");

  const subGenres = res.data.map((subgenre: any) => {
    return {
      id: subgenre.id,
      name: subgenre.name,
      type: FilterType.styleType,
    } as Filter;
  }) as Filter[];

  return {
    subGenres: [
      { id: "1", name: "Any", type: FilterType.styleType },
      ...subGenres,
    ],
  };
};
