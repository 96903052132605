import { BaseEntityType } from "@/models/enums";
import { Beat, SoundKit } from "@/models/models";
import { isBeat, isSong, isSoundkit } from "../helpers";
import { isNil } from "lodash";

export const getBaseEntityTypeFromType = (value: string | undefined | null) => {
  if (isNil(value)) {
    return null;
  }

  switch (value) {
    case "songs":
      return BaseEntityType.song;
    case "beats":
      return BaseEntityType.beat;
    case "soundkits":
    case "sounds":
      return BaseEntityType.soundkit;
    case "creator":
      return BaseEntityType.creator;
    case "links":
      return BaseEntityType.link;
    default:
      return null;
  }
};

export const getBaseEntityTypeFromValue = (value: number) => {
  return Object.entries(BaseEntityType).find(
    ([key, val]) => val === value
  )?.[1] as BaseEntityType;
};

export const getBaseEntityFromItem = (
  item: Beat | SoundKit | undefined
): BaseEntityType | null => {
  if (isNil(item)) {
    return null;
  }

  if (isBeat(item)) {
    return BaseEntityType.beat;
  }
  if (isSong(item)) {
    return BaseEntityType.song;
  }
  if (isSoundkit(item)) {
    return BaseEntityType.soundkit;
  }
  return null;
};
