import api from "@/lib/api-config";
import { useSession } from "next-auth/react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useJwtToken } from "./atoms/session-data.atom";
import { isNil } from "lodash";

export type ApiConfigurationProviderProps = {
  waitUntilSettled?: boolean;
} & PropsWithChildren;

/**
 * This provider configures the {@link api} object to include the Authorization
 * header on subsequent authorized calls. It removes the header when not logged
 * in.
 */
const ApiConfigurationProvider = ({
  waitUntilSettled = false,
  children,
}: ApiConfigurationProviderProps) => {
  const session = useSession();
  const [id, setId] = useState(0);
  const [token, setToken] = useJwtToken();

  useEffect(() => {
    if (
      !id &&
      session.status === "authenticated" &&
      session.data?.user?.accessToken
    ) {
      const interceptorId = api.interceptors.request.use((config) => {
        if (!config.headers.hasAuthorization()) {
          config.headers[
            "Authorization"
          ] = `Bearer ${session.data?.user?.accessToken}`;
        }
        return config;
      });
      setId(interceptorId);
      setToken(session.data?.user?.accessToken);
    } else if (
      token !== session.data?.user.accessToken &&
      session.status === "authenticated" &&
      !isNil(session.data?.user.accessToken)
    ) {
      api.interceptors.request.eject(id);
      const interceptorId = api.interceptors.request.use((config) => {
        if (!config.headers.hasAuthorization()) {
          config.headers[
            "Authorization"
          ] = `Bearer ${session.data?.user?.accessToken}`;
        }
        return config;
      });
      setId(interceptorId);
      setToken(session.data?.user?.accessToken);
    }

    if (id && session.status === "unauthenticated") {
      api.interceptors.request.eject(id);
    }
  }, [id, session.data?.user.accessToken, session.status, setToken, token]);

  return <>{children}</>;
};

export default ApiConfigurationProvider;
