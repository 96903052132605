import Image from "next/image";
import { Size } from "../../models/enums";
import styles from "../../styles/KitArt.module.css";
import Icon from "../icons/icon";
import { useAudioPlayer } from "../../src/contexts/AudioPlayerContext";
import { LoaderParams, SoundKit } from "../../models/models";
import { useEffect, useState } from "react";
import { getTimeStamp } from "../../lib/helpers";
import Edit from "../icons/lib/edit";
import Pause from "../icons/lib/pause";
import Play from "../icons/lib/play";
import Soundkits from "../icons/lib/soundkits";
import BaseSkeleton from "../base-skeleton";
import { cx } from "@/lib/utils/className.utils";

export type KitArtProps = {
  id?: string;
  item: SoundKit;
  size?: Size;
  play?: boolean;
  edit?: boolean;
  disabled?: boolean;
  onClick?: Function;
  onPlay?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: JSX.Element;
  className?: string;
} & LoaderParams;

const KitArt = ({
  id,
  item,
  size,
  play = false,
  edit = false,
  disabled = false,
  onClick,
  onPlay,
  children,
  className,
  isLoading,
}: KitArtProps) => {
  // hooks
  const { selectedAudio, playing } = useAudioPlayer();

  // states
  const [src, setSrc] = useState<string | null>(item.cover);

  useEffect(() => {
    try {
      const url = new URL(item?.cover ?? "");
      if (url.searchParams.has("t")) {
        setSrc(item?.cover);
      } else {
        url.searchParams.set("t", getTimeStamp().toString());
        setSrc(url.toString());
      }
    } catch (error) {
      setSrc(item?.cover);
    }
  }, [item]);

  const getSize = () => {
    switch (size) {
      case "xs":
        return "min-w-[64px] min-h-[52px] rounded-tl-lg rounded-bl-lg rounded-br-lg";
      case "sm":
        return "min-w-[70px] min-h-[56px] lg:w-[81px] lg:h-[64px] rounded-xl";
      case "md":
        return "w-full rounded-tl-xl rounded-bl-xl rounded-br-xl min-w-[100px] min-h-[80px]";
      case "lg":
        return "w-full rounded-2xl md:rounded-3xl min-w-[100px] min-h-[80px] md:min-w-[134px] md:min-h-[100px] lg:min-w-[200px] lg:min-h-[170px] before:pt-[75%] before:content-[''] before:block";
      case "xl":
        return "w-full rounded-3xl max-w-[266px] max-h-[230px] lg:max-w-[266px] lg:max-h-[230px] 2xl:max-w-[346px] xl:max-h-[300px] before:pt-[75%] before:content-[''] before:block";
      case "free":
        return "rounded-3xl w-full h-full";
      default:
        return "rounded-3xl";
    }
  };

  const getIconSize = () => {
    switch (size) {
      case "xs":
        return "30";
      case "sm":
        return "40";
      default:
        return "80";
    }
  };

  if (isLoading) {
    return (
      <BaseSkeleton
        className={cx("aspect-[6/5] w-full rounded-3xl", getSize(), className)}
      />
    );
  }

  return (
    <div
      id={id}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        onClick?.(e)
      }
      className={`flex relative justify-center items-center overflow-hidden cursor-pointer shadow-[0_0_15px_5px] shadow-[#000000]/[0.1] bg-white ${className} ${
        styles.folder
      } ${
        !src && "bg-gradient-to-br from-purple to-green"
      } shrink-0 aspect-[6/5] w-full ${getSize()}`}
    >
      {src ? (
        <Image
          src={src}
          layout="fill"
          className="object-cover aspect-[6/5] w-full shrink-0"
          placeholder="blur"
          blurDataURL={"/assets/images/placeholder.png"}
          alt={`Cover of ${item.name}`}
          onError={() => setSrc(null)}
        />
      ) : (
        <div className="flex justify-center items-center px-[30%] pt-[10%] w-full">
          <Icon icon={Soundkits} color="white" className="h-full w-full" />
        </div>
      )}

      <div className="absolute top-0 right-[-2px] w-8/12 z-20">
        <svg
          width="212"
          height="68"
          viewBox="0 0 212 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-full w-full"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M211.304 0H0.911133C7.634 0 13.9071 3.37766 17.608 8.99019L32.3684 31.375C36.0692 36.9875 42.3423 40.3652 49.0652 40.3652H184.688C199.38 40.3652 211.304 52.4209 211.304 67.2753V0Z"
            className="fill-background"
          />
        </svg>
      </div>
      {play && !disabled && (
        <div
          className={`absolute flex justify-center items-center top-0 w-full h-full bg-shadow z-10 rounded-xl ${
            selectedAudio?.id === item.id ? "opacity-100" : "opacity-0"
          } hover:opacity-100 transition-all`}
        >
          <div
            onClick={(e) => {
              onPlay ? onPlay(e) : onClick && onClick();
              e.stopPropagation();
            }}
            className={`h-[${getIconSize()}px] w-[${getIconSize()}px] group`}
          >
            <Icon
              icon={selectedAudio?.id === item.id && playing ? Pause : Play}
              className={`h-full w-full fill-green ${
                selectedAudio?.id === item.id
                  ? "block"
                  : "hidden group-hover:block"
              }`}
            />
          </div>
        </div>
      )}
      {edit && (
        <div className="absolute inset-0 flex bg-black bg-opacity-60 opacity-0 hover:opacity-100 transition-opacity duration-300">
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="flex items-center gap-2 mt-5">
              <Icon
                icon={Edit}
                color="white"
                className="h-[20px] w-[20px] mb-1"
              />
              <p className="text-white">Edit</p>
            </div>
          </div>
        </div>
      )}
      {disabled && (
        <div className="absolute inset-0 flex bg-white bg-opacity-30" />
      )}
      {children}
    </div>
  );
};

export default KitArt;
