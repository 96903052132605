import { MAX_BPM, MAX_PRICE } from "../components/filter";
import { FilterType, ItemStatus, SortOptions } from "../models/enums";
import {
  Artist,
  Beat,
  FileType,
  Filter,
  LicenseRight,
  Mood,
  SubGenre,
} from "../models/models";
import api from "./api-config";
import { SearchConfig } from "./types/search/search-config";
import { SearchCountConfig } from "./types/search/search-count-config";
import { getSortingFromOption } from "./utils/sales-purchases/getSortingFromOption";
import { getFilterFromOptions } from "./utils/search/getFilterFromOptions";
import { getSortingFromOptions } from "./utils/search/getSortingFromOptions";

export const createSongAsync = async (
  name: string,
  artistId: string,
  token: string
) => {
  const res = await api.post(
    `/songs`,
    {
      name: name,
      bpm: 0,
      status: ItemStatus.Processing,
      unlimited_distribution: false,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return { song: mapSongJsonToBeat(res.data) };
};

export const getSongsAsyncController = new AbortController();

export const getSongsAsync = async (
  query?: string,
  appliedFilters?: Filter[],
  sort?: any,
  order?: boolean,
  limit?: number,
  offset?: number,
  status?: number,
  includeAll?: boolean
) => {
  const filter = getFilterFromOptions({
    query,
    filters: appliedFilters,
    status,
  });
  const sorting = getSortingFromOption(sort);

  const songs = await getSongs({
    filter,
    sorting,
    limit,
    offset,
    includeAll,
  });
  return { songs };
};

/**
 * Perform a search to obtain songs by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Song}.
 */
export const getSongs = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/songs/search",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getSongsAsyncController.signal }
  );

  return res.data?.map((beat: any) => mapSongJsonToBeat(beat)) as Beat[];
};

const getSongsOptimizedAsyncController = new AbortController();

/**
 * Perform a search to obtain songs by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Song}.
 */
export const getSongsOptimized = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/songs/search/optimized",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getSongsOptimizedAsyncController.signal }
  );

  return res.data?.map((beat: any) => mapSongJsonToBeat(beat)) as Beat[];
};

export const getAudiolabSongsAsyncController = new AbortController();

export type GetAudiolabSongsParams = { token?: string | null } & SearchConfig;

/**
 * Perform a search to obtain audiolab songs by the given filters and sorting
 * options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Song}.
 */
export const getAudiolabSongs = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
  token,
}: GetAudiolabSongsParams) => {
  const res = await api.post(
    "/audiolab/songs/search",
    {
      filter,
      sorting,
      limit,
      offset,
      includeAll,
    },
    {
      signal: getAudiolabSongsAsyncController.signal,
      ...(token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}),
    }
  );

  return res.data?.map((beat: any) => mapSongJsonToBeat(beat)) as Beat[];
};

export const getSongsCountAsync = async (
  query?: string,
  appliedFilters?: Filter[]
) => {
  const genre = appliedFilters?.find(
    (filter) => filter.type === FilterType.genreType
  )?.id;
  const styles = appliedFilters
    ?.filter((filter) => filter.type === FilterType.styleType)
    .map((fil) => fil.id);
  const moods = appliedFilters
    ?.filter((filter) => filter.type === FilterType.moodsType)
    .map((fil) => fil.id);

  const keys = appliedFilters
    ?.filter((filter) => filter.type === FilterType.keyType)
    .map((fil) => fil.id);
  const date =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.recencyType)
        ?.id!
    ) - 1;
  const license =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.licenseType)
        ?.id!
    ) - 2;
  const rights = appliedFilters
    ?.filter((filter) => filter.type === FilterType.rightsType)
    .map((fil) => Number(fil.id) - 1);
  const artists = appliedFilters
    ?.filter((filter) => filter.type === FilterType.artistType)
    .map((fil) => fil.id);

  let priceFrom: number = 0;
  let priceTo: number = MAX_PRICE;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.priceType) {
      priceFrom = parseInt(filter.name.replaceAll("$", "").split("-").at(0)!);
      priceTo = parseInt(filter.name.replaceAll("$", "").split("-").at(1)!);
    }
  });

  let bpmFrom: number = 0;
  let bpmTo: number = MAX_BPM;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.bpmType) {
      bpmFrom = parseInt(filter.name.split("-").at(0)!);
      bpmTo = parseInt(filter.name.split("-").at(1)!);
    }
  });

  const res = await api.post("/songs/count", {
    filter: {
      name: query,
      genres: genre ? [genre] : null,
      sub_genres: styles?.length! > 0 ? styles : null,
      moods: moods?.length! > 0 ? moods : null,
      price_from: priceFrom === 0 && priceTo === MAX_PRICE ? null : priceFrom,
      price_to: priceTo === MAX_PRICE && priceFrom === 0 ? null : priceTo,
      bpm_from: bpmFrom === 0 && bpmTo === MAX_BPM ? null : bpmFrom,
      bpm_to: bpmTo === MAX_BPM && bpmFrom === 0 ? null : bpmTo,
      keys: keys?.length! > 0 ? keys : null,
      date_added: date,
      license_type: license,
      license_rights: rights,
      artist_ids: artists?.length! > 0 ? artists : null,
      status: 0,
    },
  });
  return { count: res.data };
};

/**
 * Counts how many elements are in DB with the given filters.
 *
 * @param config is the configuration params
 * @returns the count
 */
export const getSongsCount = async ({ filter }: SearchCountConfig) => {
  const res = await api.post("/songs/count", {
    filter,
  });
  return { count: res.data };
};

export const getSongByIdAsync = async (id: string) => {
  const songJson = await (await api.get(`/songs/${id}`)).data;
  return { song: mapSongJsonToBeat(songJson) };
};

export const updateSongByIdAsync = async (
  id: string,
  payload: any,
  token?: string
) => {
  try {
    const res = await api.put(
      `/songs/${id}`,
      payload,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );

    if (res) {
      return { song: mapSongJsonToBeat(res.data) };
    }
  } catch (e) {
    throw e;
  }
  return {};
};

export const mapSongJsonToBeat = (songJson: any): Beat => {
  return {
    id: songJson.id,
    name: songJson.name,
    cover: songJson.pictureUrl,
    tempo: songJson.bpm,
    duration: songJson.duration,
    isMp3: songJson.isMp3,
    price: parseFloat(songJson.min_price),
    lyrics: songJson.lyrics || null,
    sample: songJson.audio_preview,
    vocals: songJson.vocals || null,
    genres: songJson.subGenres?.length > 0 && songJson.subGenres[0]?.genre,
    styles:
      songJson.subGenres?.map((sub: any) => {
        return {
          id: sub.id,
          name: sub.name,
          genreId: sub.genre_id,
          type: FilterType.styleType,
        } as SubGenre;
      }) ?? null,
    moods:
      songJson.moods?.map((mood: any) => {
        return {
          id: mood.id,
          name: mood.name,
          // relatedMoods: mood.relatedMoods,
          type: FilterType.moodsType,
        } as Mood;
      }) ?? null,
    soundsLike: songJson.soundslike?.map((sl: any) => sl.name) ?? [],
    keySignature: songJson.key || null,
    artist:
      songJson.artist &&
      ({
        id: songJson.artist.id,
        name: songJson.artist.name,
        firstName: songJson.artist.firstName || null,
        lastName: songJson.artist.lastName || null,
        bio: songJson.artist.bio || null,
        createdAt: songJson.artist.createdAt || null,
        profilePicUrl: songJson.artist.profilePicUrl,
      } as Artist),
    licenseRights:
      songJson.license_rights?.map((right: any) => {
        return {
          id: right.id,
          price: right.extra_price,
          name: right.license_right,
          files: right.files?.map((file: any) => {
            return {
              id: file.id,
              type: file.type,
              extraPrice: file.extra_price,
            } as FileType;
          }),
        } as LicenseRight;
      }) || null,
    waveForms: {
      desktopUrl: songJson?.wave_forms?.desktop_url ?? null,
      mobileUrl: songJson?.wave_forms?.mobile_url ?? null,
    },
    publishDate: songJson?.published_date,
    status: songJson?.status,
    type: "songs",
    files: songJson?.files ?? [],
  } as Beat;
};
