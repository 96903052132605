import { CartProvider } from "@/src/contexts/CartContext";
import { SessionProvider } from "next-auth/react";
import React, { PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import AudioPlayerLayoutWithContext from "./AudioPlayerLayout";
import ApiConfigurationProvider from "./ApiConfigurationProvider";
import { Provider } from "jotai";
import { store } from "@/lib/utils/createAtomStore";
import StatsigProvider from "./StatsigProvider";

export type BaseProvidersProps = PropsWithChildren;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError(err) {
        console.error(err);
      },
    },
    mutations: {
      onError(err) {
        console.error(err);
      },
    },
  },
});

/**
 * Wrapper that use only the required context providers for basic functionality
 * of the app.
 *
 * This will exclude contexts such as filters, query, web services, etc., and the
 * aim is to have a lightweight for the homepage and other pages that don't
 * require complex functionality.
 */
const BaseProviders = ({ children }: BaseProvidersProps) => {
  return (
    <Provider store={store}>
      <SessionProvider refetchInterval={2 * 60}>
        <ApiConfigurationProvider waitUntilSettled>
          <QueryClientProvider client={queryClient}>
            <CartProvider>
              <AudioPlayerLayoutWithContext>
                <StatsigProvider>
                  {children}
                </StatsigProvider>
              </AudioPlayerLayoutWithContext>
            </CartProvider>
          </QueryClientProvider>
        </ApiConfigurationProvider>
        <Toaster />
      </SessionProvider>
    </Provider>
  );
};

export default BaseProviders;
