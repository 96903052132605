import { BaseEntityType } from "../models/enums";
import {
  Beat,
  FileType,
  SoundKit,
  Artist,
  GenericResponse,
  FileWithUrl,
  Status,
} from "../models/models";
import api from "./api-config";
import { mapBeatJsonToBeat } from "./beats-manager";
import { mapSongJsonToBeat } from "./songs-manager";
import { mapSoundKitJsonToSoundKit } from "./soundkits-manager";
import { DetailEntity } from "./types/audiolab";
import { getCategoryStringFromBaseEntityType } from "./utils/audiolab/getCategoryStringFromBaseEntityType";
import { SearchConfig } from "./types/search/search-config";
import { RadioCutlist } from "./types/radio-cutlist.type";
import { getBaseEntityTypeFromType } from "./utils/getBaseEntityTypeFromType";
import { IdentifiableEntity } from "./types/identifiable-entity.type";
import { isNil, omitBy } from "lodash";
import { WaveformData } from "@/components/account/audiolab/controls/RadioCutlist/types/waveform-data.type";
import * as appConfig from "./app-config";

export type IdentifiableBaseType = {
  id: string;
  type: BaseEntityType;
};

// Automatic info generation endpoints
export const startProcessingBeat = async (
  id: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-beat/${id}`);
  return res.data;
};

export const startProcessingSong = async (
  id: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-song/${id}`);
  return res.data;
};

export const startProcessingSoundkit = async (
  id: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-soundkit/${id}`);
  return res.data;
};

export const startProcessingArtist = async (
  id: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-artist/${id}`);
  return res.data;
};

export type GetLoadEntityParams = {
  id: string;
  type?: BaseEntityType | null;
  force?: boolean;
};

/**
 * Performs a GET call to the API according to the type of the item.
 *
 * @param item is the item to process
 * @param token the access token
 * @returns the data from the call
 */
export const getLoadEntity = async ({
  id,
  type,
  force = false,
}: GetLoadEntityParams) => {
  try {
    if (type === BaseEntityType.soundkit) {
      await startProcessingSoundkit(id, force);
    } else if (type === BaseEntityType.song) {
      await startProcessingSong(id, force);
    } else if (type === BaseEntityType.beat) {
      await startProcessingBeat(id, force);
    } else if (type === BaseEntityType.creator) {
      await startProcessingArtist(id, force);
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Performs a GET call to the API to load an artists from Audiolab.
 *
 * @param artist is the artist to load
 * @param token the access token of the user
 * @param force if it should be called by force
 * @returns the data from the call
 */
export const getLoadArtist = async (artist: Artist, force = false) => {
  return await startProcessingArtist(artist.id, force);
};

export const getIsRadioEditGenerated = async (
  id: string | undefined,
  type: BaseEntityType | undefined
) => {
  try {
    const suffix =
      appConfig.API_URL === "https://api.licenselounge.com"
        ? ""
        : "-test";
    const bucket = `licenselounge-${getCategoryStringFromBaseEntityType(
      type
    )}${suffix}`;
    const key = `${id}/radio.png`;
    const res = await api.get(`audiolab/read?bucket=${bucket}&key=${key}`);

    return res.data && res.data.status === "success";
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRadioEditStatus = async (
  id?: string | undefined | null,
  type?: BaseEntityType | undefined | null
) => {
  if (isNil(id) || isNil(type)) {
    return null;
  }

  const res = await api.get<GenericResponse<Status>>(
    `/audiolab/radio-edit-status/${id}/${type}`
  );
  return res.data;
};

export type GetRadioEditUrlsAsyncReturn = {
  files: FileWithUrl[];
};

export const getRadioEditUrlsAsync = async (
  id?: string | null,
  type?: BaseEntityType | undefined | null,
  token?: string
) => {
  try {
    if (isNil(id) || isNil(type)) {
      return null;
    }

    const res = await api.post<GetRadioEditUrlsAsyncReturn>(
      `/audiolab/get-radio-edit-urls/${id}/${type}`,
      null,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const startGenerateRadioEditAsync = async (
  id: string | undefined,
  type: BaseEntityType | undefined,
  token?: string
) => {
  try {
    const res = await api.post(
      `/audiolab/generate-radio-edit/${id}/${type}`,
      null,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type AudiolabStatus = {
  name: string;
  status: string;
};

export const getStatus = async (
  id: string,
  type: BaseEntityType,
  token?: string
) => {
  try {
    const res = await api.get<AudiolabStatus[]>(
      `/audiolab/status/${id}/${type}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Main Audiolab + Audiolab detail endpoints
export const getAudiolabEntityDetail = async (
  id: string,
  type: BaseEntityType | undefined,
  token?: string
) => {
  const itemJson = (
    await api.get<DetailEntity>(
      `/audiolab/detail-entity/${id}/${type}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    )
  ).data;

  return { item: itemJson };
};

export const getAll = async (token: string, payload: AllTrackRequest) => {
  const res = await api.post(`audiolab/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const results = res.data.map((res: any) => {
    if (!res.is_royalty_free && res.bpm !== undefined && !res.type) {
      return mapBeatJsonToBeat(res);
    } else if (res.type === BaseEntityType.song) {
      return mapSongJsonToBeat(res);
    } else {
      return mapSoundKitJsonToSoundKit(res);
    }
  });

  return { results: results as Beat[] | SoundKit[] };
};

export const getBeats = async (
  token: string | undefined,
  payload: AllTrackRequest
) => {
  try {
    const res = await api.post(
      `audiolab/beats/search`,
      payload,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );

    const results = res.data.map((res: any) => {
      return mapBeatJsonToBeat(res);
    });

    return { results: results as Beat[] };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSongs = async (
  token: string | undefined,
  payload: AllTrackRequest
) => {
  const res = await api.post(
    `audiolab/songs/search`,
    payload,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}
  );

  const results = res.data.map((res: any) => {
    return mapSongJsonToBeat(res);
  });

  return { results: results as Beat[] };
};

export const getSoundkits = async (
  token: string | undefined,
  payload: AllTrackRequest
) => {
  const res = await api.post(
    `audiolab/soundkits/search`,
    payload,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}
  );

  const results = res.data.map((res: any) => {
    return mapSoundKitJsonToSoundKit(res);
  });

  return { results: results as SoundKit[] };
};

export const deleteItemAsync = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.post(
      `/audiolab/remove`,
      {
        entity_id: id,
        type: type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { beat: mapBeatJsonToBeat(res.data) };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const exportStemAsync = async (
  id: string,
  format: string,
  name: string,
  itemType: BaseEntityType
) => {
  const res = await api.post(`/audiolab/export`, {
    entity_id: id,
    format: format,
    file_name: name,
    type: itemType,
  });

  if (res.data.status === 400) {
    throw new Error();
  }

  if (res.data) {
    return { file: res.data.file };
  } else {
    return { file: null };
  }
};

export type ExportStemParams = {
  id: string;
  format: string;
  name: string;
  itemType: BaseEntityType;
};

export const exportStem = async ({
  id,
  format,
  name,
  itemType,
}: ExportStemParams) => {
  const res = await api.post(`/audiolab/export`, {
    entity_id: id,
    format: format,
    file_name: name,
    type: itemType,
  });

  if (res.data.status === 400) {
    throw new Error();
  }

  if (res.data) {
    return { file: res.data.file };
  } else {
    return { file: null };
  }
};

export const getAudiolabBeatsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/beats/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.data) {
    return resp.data;
  }
};

export const getAudiolabSongsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/songs/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.data) {
    return resp.data;
  }
};

export const getAudiolabSoundsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/soundkits/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.data) {
    return resp.data;
  }
};

export type CoverArtParams = Partial<IdentifiableEntity>;

export type SelectCoverArtParams = { item: number } & CoverArtParams;

// Cover art API calls
export const postSelectCoverArt = async (payload: SelectCoverArtParams) => {
  try {
    const resp = await api.post(
      `/audiolab/select-cover-art/${payload.id}/${payload.type}/${payload.item}`,
      null
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postSelectCoverArt: ${error}`);
    throw error;
  }
};

export type CoverArtStatusReturn = {
  status: "initial" | "inprogress" | "complete";
};

export const getCoverArtStatus = async (payload: CoverArtParams) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/status-cover-art/${payload.id}/${payload.type}`,
      null
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`getCoverArtStatus: ${error}`);
    throw error;
  }
};

export type GetCoverArtsReturn = { files: FileType[] };

export const getCoverArts = async (payload: CoverArtParams) => {
  try {
    const resp = await api.post<GetCoverArtsReturn>(
      `/audiolab/get-cover-art/${payload.id}/${payload.type}`,
      null
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`getCoverArts: ${error}`);
    throw error;
  }
};

export const getCoverArtsJson = async (payload: CoverArtParams) => {
  try {
    const resp = await api.post<GetCoverArtsReturn>(
      `/audiolab/get-cover-art-json/${payload.id}/${payload.type}`,
      null
    );
    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`getCoverArtsJson: ${error}`);
    throw error;
  }
};

export const postResetCoverArts = async (payload: CoverArtParams) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/reset-cover-art/${payload.id}/${payload.type}`,
      null
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postResetCoverArts: ${error}`);
    throw error;
  }
};

export type PostGenerateCoverArtParams = {
  body?: { prompt?: string };
} & CoverArtParams;

export const postGenerateCoverArt = async (
  token: string,
  payload: PostGenerateCoverArtParams
) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/generate-cover-art/${payload.id}/${payload.type}`,
      payload.body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postGenerateCoverArt: ${error}`);
    throw error;
  }
};

/**
 * Triggers the entity duration task for the given type and id.
 *
 * @param type is the type of the entity, one of beat | song
 * @param id is the id of the entity
 * @returns the response as a {@link GenericResponse}
 */
export const getUpdateEntityDuration = async (
  type: "beat" | "song",
  id: string
) => {
  try {
    const response = await api.get<GenericResponse>(
      `/audiolab/update-entity-duration/${type}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type PostStartLyricsGenerationParams = {
  type: "beats" | "songs" | "creators" | "sounds";
  id: string;
  generateRadioEdit?: boolean;
  language?: string;
};

/**
 * Starts the lyrics generation from audiolab as a task.
 *
 * @returns a {@link GenericResponse}.
 */
export const postStartLyricsGeneration = async ({
  type,
  id,
  generateRadioEdit = false,
  language,
}: PostStartLyricsGenerationParams) => {
  try {
    const response = await api.post<GenericResponse>(
      `/audiolab/lyrics/${getBaseEntityTypeFromType(type)}/${id}`,
      omitBy({ generateRadioEdit, language }, (prop) => isNil(prop))
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type LyricsGenerationStatusData = {
  status: "initial" | "inprogress" | "complete";
};

/**
 * @returns a {@link GenericResponse}.
 */
export const getLyricsGenerationStatus = async ({
  id,
  type,
}: IdentifiableEntity) => {
  try {
    const response = await api.get<
      GenericResponse<{ status: "initial" | "inprogress" | "complete" }>
    >(`/audiolab/lyrics-status/${type}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * @returns a {@link GenericResponse} which in its `data` prop contains the
 * lyrics of the item. `data` will be null if lyrics are not present on S3
 * folder.
 */
export const getItemLyrics = async ({ id, type }: IdentifiableEntity) => {
  try {
    const response = await api.get<GenericResponse<string>>(
      `/audiolab/lyrics/${type}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type PutItemLyricsParams = { lyrics: string } & IdentifiableEntity;

export const putItemLyrics = async ({
  id,
  type,
  lyrics,
}: PutItemLyricsParams) => {
  try {
    const response = await api.put<GenericResponse<string>>(
      `/audiolab/lyrics/${type}/${id}`,
      { lyrics }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type AllTrackRequest = {
  filter: {
    id: string | null;
    name: string | null;
    statuses: number[] | null;
    entity_types: number[];
  };
  sorting: {
    sort_isAlphabetical: boolean | null;
    sort_isNewest: boolean | null;
    sort_BPM: boolean | null;
  };
  limit: number | null;
  offset: number | null;
  includeAll?: boolean;
};

// Generated by https://quicktype.io

export interface AudiolabCountRequest {
  filter: {
    statuses: number[];
    name?: string;
  };
}

export const fetchAssets = async (
  token: string,
  payload: AllTrackRequest
): Promise<(Beat | SoundKit)[]> => {
  const res = await api.post(`audiolab/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const results = res.data.map((res: any) => {
    if (!res.is_royalty_free && res.bpm !== undefined && !res.type) {
      return mapBeatJsonToBeat(res);
    } else if (res.type === BaseEntityType.song) {
      return mapSongJsonToBeat(res);
    } else {
      return mapSoundKitJsonToSoundKit(res);
    }
  });

  return results;
};

export const getRadioCutlistAuto = async ({
  id,
  type,
}: IdentifiableBaseType) => {
  try {
    const radioCutlist = await api.get<GenericResponse<RadioCutlist>>(
      `audiolab/cutlist-auto/${type}/${id}`
    );
    return radioCutlist.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export type Bleep = "silence" | "fuzz" | "reverse";

export const getRadioCutlistUser = async ({
  id,
  type,
}: IdentifiableBaseType) => {
  try {
    const radioCutlist = await api.get<
      GenericResponse<{ bleep: Bleep; radioCutlist: RadioCutlist }>
    >(`audiolab/cutlist/${type}/${id}`);
    return radioCutlist.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type PutRadioCutlistUserParams = IdentifiableEntity & {
  radioCutlist: RadioCutlist;
  bleep?: Bleep;
};

export const putRadioCutlistUser = async ({
  id,
  type,
  radioCutlist,
  bleep,
}: PutRadioCutlistUserParams) => {
  try {
    const response = await api.put<GenericResponse<RadioCutlist>>(
      `audiolab/cutlist/${type}/${id}`,
      {
        radioCutlist,
        bleep,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type GetWaveformDataParams = {
  id: string;
  type: BaseEntityType;
};

export const getWaveformData = async ({ id, type }: GetWaveformDataParams) => {
  try {
    const data = await api.get<GenericResponse<WaveformData>>(
      `audiolab/waveform/${type}/${id}`
    );
    return data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
